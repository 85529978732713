import { render, staticRenderFns } from "./2-buat-realisasi.vue?vue&type=template&id=7c0a5a65&"
import script from "./2-buat-realisasi.vue?vue&type=script&lang=js&"
export * from "./2-buat-realisasi.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports